import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function StopThisTrain() {
  return (
    <Layout>
      <Helmet title="Stop This Train | John Mayer" />

      <h1>Stop This Train</h1>

      <h2>John Mayer</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>Dsus2 DMaj13 Bm9/D Asus2/D</p>
        </Verse>
        <Verse>
          <p>
            <Ch>GMaj7</Ch>No, I'm not colorblind
          </p>
          <p>
            I know the world is black and <Ch>Dsus2</Ch>white
          </p>
          <p>
            <Ch>GMaj7</Ch>I try to keep an open mind
          </p>
          <p>
            But I just can't sleep on this <Ch>Dsus2</Ch>tonight
          </p>
        </Verse>
        <Chorus>
          <p>
            <Ch>GmMaj7</Ch>Stop this train
          </p>
          <p>
            I want to get off and go <Ch>D/F#</Ch>home again
          </p>
          <p>
            I can't take the speed it's <Ch>E9</Ch>moving in
          </p>
          <p>I know I can't</p>
          <p>
            But <Ch>Gsus2/A</Ch>honestly<Ch>A7</Ch>, won't <Ch>Am7</Ch>
            someone
            <Ch>A9</Ch>stop this <Ch>Dsus2</Ch>train?
          </p>
        </Chorus>
        <Verse>
          <p>
            <Ch>GMaj7</Ch>Don't know how else to say it
          </p>
          <p>
            Don't want to see my parents <Ch>Dsus2</Ch>go
          </p>
          <p>
            <Ch>GMaj7</Ch>I'm one generation's length away
          </p>
          <p>
            From fighting life out on my <Ch>Dsus2</Ch>own
          </p>
        </Verse>
        <Chorus>
          <p>
            <Ch>GmMaj7</Ch>Stop this train
          </p>
          <p>
            I want to get off and go <Ch>D/F#</Ch>home again
          </p>
          <p>
            I can't take the speed it's <Ch>E9</Ch>moving in
          </p>
          <p>I know I can't</p>
          <p>
            But <Ch>Gsus2/A</Ch>honestly<Ch>A7</Ch>, won't <Ch>Am7</Ch>
            someone
            <Ch>A9</Ch>stop this <Ch>Dsus2</Ch>train?
          </p>
        </Chorus>
        <Verse>
          <p>
            <Ch>GMaj7</Ch>I'm so scared of getting older
          </p>
          <p>
            I'm only good at being <Ch>Dsus2</Ch>young
          </p>
          <p>
            <Ch>GMaj7</Ch>So I play the numbers game
          </p>
          <p>
            To find a way to say my life has just <Ch>Dsus2</Ch>begun
          </p>
        </Verse>
        <PreChorus>
          <p>
            <Ch>GMaj7</Ch>Had a talk with my old man
          </p>
          <p>
            Said, <Ch>Gmin7</Ch>"Help me understand"
          </p>
          <p>
            <Ch>D/F#</Ch>He said "Turn 68, oh, <Ch>E7</Ch>you'll re-negotiate"
          </p>
        </PreChorus>
        <Chorus>
          <p>
            <Ch>GmMaj7</Ch>"Don't stop this train
          </p>
          <p>
            Don't for a minute change the <Ch>D/F#</Ch>place you're in
          </p>
          <p>
            And don't think I couldn't ever <Ch>E9</Ch>understand
          </p>
          <p>I tried my hand</p>
          <p>
            John <Ch>Gsus2/A</Ch>honestly<Ch>A7</Ch>, we'll <Ch>Am7</Ch>
            never
            <Ch>A9</Ch>stop this <Ch>Dsus2</Ch>train
          </p>
        </Chorus>
        <Bridge>
          <p>
            Oh, now, <Ch>G</Ch>once in a <Ch>A11</Ch>while, when it's{" "}
            <Ch>G/B</Ch>good
          </p>
          <p>
            It'll <Ch>Em13/C#</Ch>feel like it <Ch>G</Ch>should
          </p>
          <p>
            And they're <Ch>A11</Ch>all still <Ch>G/B</Ch>around
          </p>
          <p>
            And you're <Ch>Em13/C#</Ch>still safe and <Ch>G</Ch>sound
          </p>
          <p>
            And you <Ch>A11</Ch>don't miss a <Ch>G/B</Ch>thing
          </p>
          <p>
            'Til you <Ch>Em13/C#</Ch>cry
          </p>
          <p>
            When you're <Ch>Dadd4</Ch>driving a<Ch>Am7</Ch>way in the{" "}
            <Ch>Em9</Ch>dark, yeah
          </p>
          <p>Singing</p>
        </Bridge>
        <Chorus>
          <p>
            Singing <Ch>GmMaj7</Ch>Stop this train
          </p>
          <p>
            I want to get off and go <Ch>D/F#</Ch>home again
          </p>
          <p>
            I can't take the speed it's <Ch>E9</Ch>moving in
          </p>
          <p>I know I can't</p>
          <p>
            But <Ch>Gsus2/A</Ch>honestly<Ch>A7</Ch>, won't <Ch>Am7</Ch>
            someone
            <Ch>A9</Ch>stop this <Ch>Dsus2</Ch>train?
          </p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
